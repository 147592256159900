import React, { useEffect, useRef } from 'react';
import { createContent } from '../common/Theme';
import Layout from '../components/layout';
import Hero from '../components/hero';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import { Link } from "gatsby";
import Button from '../components/button';
import ContactUs from '../components/contactUs';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import UpdateIcon from '@material-ui/icons/Update';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';

import '../assets/styles/pages/home.scss';

const IndexPage = () => {
  const classes = createContent();

  const itemOneRef = useRef(null);
  const itemTwoRef = useRef(null);
  const itemThreeRef = useRef(null);
  const itemFourRef = useRef(null);

  const processCallbackFunction = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('is-active');
      } else {
        entry.target.classList.remove('is-active');
      }
    })
  }

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0% 0% -50% 0%',
      threshold: 0
    };
    const observer = new IntersectionObserver(processCallbackFunction, options);

    const currentOneRef = itemOneRef.current;
    const currentTwoRef = itemTwoRef.current;
    const currentThreeRef = itemThreeRef.current;
    const currentFourRef = itemFourRef.current;

    if (currentOneRef) observer.observe(currentOneRef);
    if (currentTwoRef) observer.observe(currentTwoRef);
    if (currentThreeRef) observer.observe(currentThreeRef);
    if (currentFourRef) observer.observe(currentFourRef);


    return () => {
      if (currentOneRef) observer.unobserve(currentOneRef);
      if (currentTwoRef) observer.unobserve(currentTwoRef);
      if (currentThreeRef) observer.unobserve(currentThreeRef);
      if (currentFourRef) observer.unobserve(currentFourRef);
    }
  }, [itemOneRef, itemTwoRef, itemThreeRef, itemFourRef]);

  return (
    <Layout>
      <Hero />

      <section id='introduction-section'>
        <Container classes={{root: classes.content}}>
          <div className='introduction inner-container'>
            <Typography variant='h3' color='textSecondary'>
              We are a people-centric digital agency.
            </Typography>
            <Typography color='textSecondary' className='text-large'>
              We are on a mission to create digital solutions that enrich people's lives and deliver value to your customers, partners and employees.
            </Typography>
            <Link to='/projects'>
              <Button text='See our work' variant='secondary' />
            </Link>
          </div>
        </Container>
      </section>

      <section id='services-section'>
        <Container classes={{root: classes.content}} className='services-container'>
          <Typography variant='h3'>What we do</Typography>
          <Grid container spacing={0} className='services-grid'>
            <Grid item md={6} sm={12} className='services-left'>
              <div className='services-left-inner'>
                <Typography variant='h4'>Design</Typography>
                <Typography>
                  With a user-first approach to design we strip back the unnecessary and focus on the essentials - delivering intuitive digital experiences that are effective.
                </Typography>
                <Link to='/services/design'>
                  <Button text='Go to Design' small />
                </Link>
              </div>
            </Grid>
            <Grid item md={6} sm={12} className='services-right'>
              <Card elevation={0} className='services-right-inner'>
                <CardContent>
                  <ul>
                    <li>
                      <Typography variant='body2'>
                        Websites
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        User Interface Design
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        User Experience Design
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        Wireframing & Prototyping
                      </Typography>
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} sm={12} className='services-left'>
              <div className='services-left-inner'>
                <Typography variant='h4'>Development</Typography>
                <Typography>
                  We develop bespoke software solutions that grow and scale with you - enabling you to spend more time on the things that really matter.
                </Typography>
                <Link to='/services/development'>
                  <Button text='Go to Development' small />
                </Link>
              </div>
            </Grid>
            <Grid item md={6} sm={12} className='services-right'>
              <Card elevation={0} className='services-right-inner'>
                <CardContent>
                  <ul>
                    <li>
                      <Typography variant='body2'>
                        Software Development
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        Mobile App Development
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        CMS & Wordpress Development
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        API Integrations
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        Cloud Engineering
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        E-commerce
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        JAM-stack Development
                      </Typography>
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} sm={12} className='services-left last'>
              <div className='services-left-inner'>
                <Typography variant='h4'>Consulting</Typography>
                <Typography>
                  We love to work with innovative, forward thinking businesses and help them overcome their digital challenges - putting our creativity and expertise to use to help you reach your goals.
                </Typography>
                <Link to='/services/consulting'>
                  <Button text='Go to Consulting' small />
                </Link>
              </div>
            </Grid>
            <Grid item md={6} sm={12} className='services-right last'>
              <Card elevation={0} className='services-right-inner'>
                <CardContent>
                  <ul>
                    <li>
                      <Typography variant='body2'>

                        Team Augmentation
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        Data Strategy & Analytics
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        Digital Strategy
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        Prototyping
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        Cloud Migration
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        Technology Training
                      </Typography>
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section id='process-section'>
        <Container classes={{root: classes.content}} className='process-container'>
          <Grid container spacing={0}>
            <Grid item md={6} sm={12} className='process-info'>
              <div className='process-info-inner'>
                <Typography variant='h3' color='textSecondary'>How we do it</Typography>
                <Typography color='textSecondary'>
                  Every successful project starts and ends with clear communication and close collaboration.
                  From the first time we get to know each other until the final project delivery and beyond
                  our goal is to help you achieve your digital goals.
                </Typography>
              </div>
            </Grid>
            <Grid item md={6} sm={12}>
              <div className='process-item'>
                <div className='process-item-left' ref={itemOneRef}>
                  <div className='process-item-graphic'>
                    <EmojiObjectsIcon />
                  </div>
                </div>
                <div className='process-item-right'>
                  <Typography variant='h4' color='textSecondary'>Think</Typography>
                  <Typography color='textSecondary'>
                    We actively think along with you so we can understand your world and define your use case.
                    In order to come up with the perfect solution that works for you we analyze the opportunities and
                    challenges you face. The goal is to create a shared vision of the project which makes for
                    easier communication down the line and better results.
                  </Typography>
                </div>
              </div>
              <div className='process-item'>
                <div className='process-item-left' ref={itemTwoRef}>
                  <div className='process-item-graphic'>
                    <FilterCenterFocusIcon />
                  </div>
                </div>
                <div className='process-item-right'>
                  <Typography variant='h4' color='textSecondary'>Focus</Typography>
                  <Typography color='textSecondary'>
                    Our goal is to create value for you and your business.
                    We achieve this by pinpointing the key aspects of our shared vision and focusing on the things that
                    will make a difference.
                    This enables us to do what we do best while saving you
                    both time and money.
                  </Typography>
                </div>
              </div>
              <div className='process-item'>
                <div className='process-item-left' ref={itemThreeRef}>
                  <div className='process-item-graphic'>
                    <UpdateIcon />
                  </div>
                </div>
                <div className='process-item-right'>
                  <Typography variant='h4' color='textSecondary'>Iterate</Typography>
                  <Typography color='textSecondary'>
                    Communication is key in delivering great products and services. Thanks to the
                    shared vision we've developed this becomes easy, enjoyable and effective.
                    During the process we do regular progress checks to ensure we both have the same goals in mind.
                    This creates the best possible results.
                  </Typography>
                </div>
              </div>
              <div className='process-item'>
                <div className='process-item-left last' ref={itemFourRef}>
                  <div className='process-item-graphic'>
                    <ThreeSixtyIcon />
                  </div>
                </div>
                <div className='process-item-right'>
                  <Typography variant='h4' color='textSecondary'>Care</Typography>
                  <Typography color='textSecondary'>
                    We take great pride in our products and services and want them to make an impact.
                    To achieve this we don't stop when the project is finished.
                    Need help getting everyone on your team on board, keeping your product updated or
                    go for the next opportunity?
                    We're here for you, going for success together.
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section id='cases-section'>
        <Container classes={{root: classes.content}}>
          <Grid container spacing={0}>
            <Grid item md={6} sm={12} className='cases-info'>
              <div className='cases-info-inner'>
                <Typography variant='h3'>Our projects</Typography>
                <Typography>
                  We take great pride in our projects.
                  We commit to delivering the desired results for our clients and won't stop until we've achieved this.
                  Take a look at a selection of our past success stories.
                </Typography>
                <Link to='/projects'>
                  <Button text='Our projects' />
                </Link>
              </div>
            </Grid>
            <Grid item md={6} sm={12}>
              <Grid container spacing={4}>
                <Grid item md={12}>
                  <div className='case-item'>
                    <Link to='/projects/inventory-management' className='case-item-link'>
                      <div className='case-item-image-background'>
                        <div id='case-1-image' className='case-item-image'>
                        </div>
                      </div>
                      <div className='case-item-description'>
                        On the go inventory management with a mobile app
                      </div>
                    </Link>
                  </div>
                </Grid>
                <Grid item md={12}>
                  <div className='case-item'>
                    <Link to='/projects/rt112' className='case-item-link'>
                      <div className='case-item-image-background'>
                        <div id='case-2-image' className='case-item-image'>
                        </div>
                      </div>
                      <div className='case-item-description'>
                        Website, e-commerce & automatic invoicing for charity
                      </div>
                    </Link>
                  </div>

                </Grid>
                <Grid item md={12}>
                  <div className='case-item'>
                    <Link to='/projects/clearfacts' className='case-item-link'>
                      <div className='case-item-image-background'>
                        <div id='case-3-image' className='case-item-image'>
                        </div>
                      </div>
                      <div className='case-item-description'>
                        Consulting for a FinTech SaaS product
                      </div>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>

      <ContactUs />
    </Layout>
  )
}

export default IndexPage
