import React from 'react';
import {
  makeStyles,
  Container,
  Typography,
} from '@material-ui/core';

import '../assets/styles/components/hero.scss';

const useStyles = makeStyles({
  heroContainer: {
    height: '100%',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    overflow: 'hidden'
  },
});

const Hero = () => {
    const classes = useStyles();

    return (
      <div className='hero'>

        <div className='hero-background'>
          <div className='hero-background-image' />
        </div>

        <Container classes={{root: classes.heroContainer}} className='hero-container'>
          <div className='hero-content'>
            <Typography className='text-tag' variant='body2' color='textPrimary'>
              Welcome to Syntactful.
            </Typography>
            <div className='hero-title'>
              <Typography variant='h1' color='textPrimary'>
                We create bespoke software solutions that help businesses and people focus on what really matters.
              </Typography>
            </div>
          </div>
        </Container>

        <div className='hero-overlay' />
      </div>
    )
}

export default Hero;
